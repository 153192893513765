import { addPropertyControls, ControlType } from "framer"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Clipboard(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <div onClick={() => navigator.clipboard.writeText(props.value)}>
            {props.text}
        </div>
    )
}

addPropertyControls(Clipboard, {
    value: {
        type: ControlType.String,
        title: "Value",
        description: "Actually vaue to copy to clipboard",
        defaultValue: "Text",
    },
    text: {
        type: ControlType.Array,
        description: "Link to a text layer or a component",
        maxCount: 1,
        title: "Text",
        control: {
            type: ControlType.ComponentInstance,
        },
    },
})
